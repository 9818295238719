define("ember-modal-dialog/components/modal-dialog", ["exports", "@ember-decorators/component", "@ember/object", "@ember/service", "@ember/object/computed", "@ember/component", "@ember/string", "@ember/utils", "ember-modal-dialog/templates/components/modal-dialog", "@ember/debug", "@embroider/util", "@embroider/macros/es-compat2"], function (_exports, _component, _object, _service, _computed, _component2, _string, _utils, _modalDialog, _debug, _util, _esCompat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const VALID_OVERLAY_POSITIONS = ['parent', 'sibling'];
  let ModalDialog = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_modalDialog.default), _dec3 = (0, _service.inject)('modal-dialog'), _dec4 = (0, _computed.readOnly)('modalService.hasLiquidWormhole'), _dec5 = (0, _computed.readOnly)('modalService.hasLiquidTether'), _dec6 = (0, _computed.oneWay)('elementId'), _dec7 = (0, _object.computed)('attachment'), _dec8 = (0, _object.computed)('renderInPlace', 'tetherTarget', 'animatable', 'hasLiquidWormhole', 'hasLiquidTether'), _dec(_class = _dec2(_class = (_class2 = class ModalDialog extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modalService", _descriptor, this);
      _defineProperty(this, "animatable", null);
      _defineProperty(this, "clickOutsideToClose", false);
      _defineProperty(this, "destinationElementId", null);
      _defineProperty(this, "hasOverlay", true);
      _defineProperty(this, "overlayPosition", 'parent');
      // `parent` or `sibling`
      _defineProperty(this, "renderInPlace", false);
      _defineProperty(this, "targetAttachment", 'middle center');
      _defineProperty(this, "tetherClassPrefix", null);
      _defineProperty(this, "tetherTarget", null);
      _defineProperty(this, "translucentOverlay", false);
      _defineProperty(this, "value", 0);
      // pass a `value` to set a "value" to be passed to liquid-wormhole / liquid-tether
      _initializerDefineProperty(this, "hasLiquidWormhole", _descriptor2, this);
      _initializerDefineProperty(this, "hasLiquidTether", _descriptor3, this);
      _initializerDefineProperty(this, "stack", _descriptor4, this);
    }
    // pass a `stack` string to set a "stack" to be passed to liquid-wormhole / liquid-tether

    get attachmentClass() {
      let attachment = this.attachment;
      if ((0, _utils.isEmpty)(attachment)) {
        return undefined;
      }
      return attachment.split(' ').map(attachmentPart => {
        return `emd-attachment-${(0, _string.dasherize)(attachmentPart)}`;
      }).join(' ');
    }
    get whichModalDialogComponent() {
      let {
        animatable,
        hasLiquidTether,
        hasLiquidWormhole,
        tetherTarget
      } = this;
      let module = (0, _esCompat.default)(require("ember-modal-dialog/components/basic-dialog"));
      if (this.renderInPlace) {
        module = (0, _esCompat.default)(require("ember-modal-dialog/components/in-place-dialog"));
      } else if (tetherTarget && hasLiquidTether && hasLiquidWormhole && animatable === true) {
        module = (0, _esCompat.default)(require("ember-modal-dialog/components/liquid-tether-dialog"));
      } else if (tetherTarget) {
        this.ensureEmberTetherPresent();
        module = (0, _esCompat.default)(require("ember-modal-dialog/components/tether-dialog"));
      } else if (hasLiquidWormhole && animatable === true) {
        module = (0, _esCompat.default)(require("ember-modal-dialog/components/liquid-dialog"));
      }
      return (0, _util.ensureSafeComponent)(module.default, this);
    }
    init() {
      super.init(...arguments);
      if (!this.destinationElementId) {
        (0, _object.set)(this, 'destinationElementId', this.modalService.destinationElementId);
      }
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      if (false /* DEBUG */) {
        this.validateProps();
      }
    }
    validateProps() {
      let overlayPosition = this.overlayPosition;
      if (VALID_OVERLAY_POSITIONS.indexOf(overlayPosition) === -1) {
        (false && (0, _debug.warn)(`overlayPosition value '${overlayPosition}' is not valid (valid values [${VALID_OVERLAY_POSITIONS.join(', ')}])`, false, {
          id: 'ember-modal-dialog.validate-overlay-position'
        }));
      }
    }
    ensureEmberTetherPresent() {
      if (!this.modalService.hasEmberTether) {
        throw new Error('Please install ember-tether in order to pass a tetherTarget to modal-dialog');
      }
    }
    onCloseAction() {
      const onClose = this.onClose;
      // we shouldn't warn if the callback is not provided at all
      if ((0, _utils.isNone)(onClose)) {
        return;
      }
      (false && !((0, _utils.typeOf)(onClose) === 'function') && (0, _debug.assert)('onClose handler must be a function', (0, _utils.typeOf)(onClose) === 'function'));
      onClose();
    }
    onClickOverlayAction(e) {
      e.preventDefault();
      const onClickOverlay = this.onClickOverlay;
      // we shouldn't warn if the callback is not provided at all
      if ((0, _utils.isNone)(onClickOverlay)) {
        this.onCloseAction();
        return;
      }
      (false && !((0, _utils.typeOf)(onClickOverlay) === 'function') && (0, _debug.assert)('onClickOverlay handler must be a function', (0, _utils.typeOf)(onClickOverlay) === 'function'));
      onClickOverlay();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "modalService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "hasLiquidWormhole", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "hasLiquidTether", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "stack", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "attachmentClass", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "attachmentClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "whichModalDialogComponent", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "whichModalDialogComponent"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onCloseAction", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onCloseAction"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onClickOverlayAction", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onClickOverlayAction"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = ModalDialog;
});