define("tracked-built-ins/-private/set", ["exports", "ember-tracked-storage-polyfill"], function (_exports, _emberTrackedStoragePolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TrackedWeakSet = _exports.TrackedSet = void 0;
  let _Symbol$iterator, _Symbol$toStringTag, _Symbol$toStringTag2;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  _Symbol$iterator = Symbol.iterator;
  _Symbol$toStringTag = Symbol.toStringTag;
  class TrackedSet {
    storageFor(key) {
      const storages = this.storages;
      let storage = storages.get(key);
      if (storage === undefined) {
        storage = (0, _emberTrackedStoragePolyfill.createStorage)(null, () => false);
        storages.set(key, storage);
      }
      return storage;
    }
    dirtyStorageFor(key) {
      const storage = this.storages.get(key);
      if (storage) {
        (0, _emberTrackedStoragePolyfill.setValue)(storage, null);
      }
    }
    constructor(existing) {
      _defineProperty(this, "collection", (0, _emberTrackedStoragePolyfill.createStorage)(null, () => false));
      _defineProperty(this, "storages", new Map());
      _defineProperty(this, "vals", void 0);
      this.vals = new Set(existing);
    }

    // **** KEY GETTERS ****
    has(value) {
      (0, _emberTrackedStoragePolyfill.getValue)(this.storageFor(value));
      return this.vals.has(value);
    }

    // **** ALL GETTERS ****
    entries() {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      return this.vals.entries();
    }
    keys() {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      return this.vals.keys();
    }
    values() {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      return this.vals.values();
    }
    forEach(fn) {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      this.vals.forEach(fn);
    }
    get size() {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      return this.vals.size;
    }
    [_Symbol$iterator]() {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      return this.vals[Symbol.iterator]();
    }
    get [_Symbol$toStringTag]() {
      return this.vals[Symbol.toStringTag];
    }

    // **** KEY SETTERS ****
    add(value) {
      this.dirtyStorageFor(value);
      (0, _emberTrackedStoragePolyfill.setValue)(this.collection, null);
      this.vals.add(value);
      return this;
    }
    delete(value) {
      this.dirtyStorageFor(value);
      (0, _emberTrackedStoragePolyfill.setValue)(this.collection, null);
      return this.vals.delete(value);
    }

    // **** ALL SETTERS ****
    clear() {
      this.storages.forEach(s => (0, _emberTrackedStoragePolyfill.setValue)(s, null));
      (0, _emberTrackedStoragePolyfill.setValue)(this.collection, null);
      this.vals.clear();
    }
  }

  // So instanceof works
  _exports.TrackedSet = TrackedSet;
  Object.setPrototypeOf(TrackedSet.prototype, Set.prototype);
  _Symbol$toStringTag2 = Symbol.toStringTag;
  class TrackedWeakSet {
    storageFor(key) {
      const storages = this.storages;
      let storage = storages.get(key);
      if (storage === undefined) {
        storage = (0, _emberTrackedStoragePolyfill.createStorage)(null, () => false);
        storages.set(key, storage);
      }
      return storage;
    }
    dirtyStorageFor(key) {
      const storage = this.storages.get(key);
      if (storage) {
        (0, _emberTrackedStoragePolyfill.setValue)(storage, null);
      }
    }
    constructor(values) {
      _defineProperty(this, "storages", new WeakMap());
      _defineProperty(this, "vals", void 0);
      this.vals = new WeakSet(values);
    }
    has(value) {
      (0, _emberTrackedStoragePolyfill.getValue)(this.storageFor(value));
      return this.vals.has(value);
    }
    add(value) {
      // Add to vals first to get better error message
      this.vals.add(value);
      this.dirtyStorageFor(value);
      return this;
    }
    delete(value) {
      this.dirtyStorageFor(value);
      return this.vals.delete(value);
    }
    get [_Symbol$toStringTag2]() {
      return this.vals[Symbol.toStringTag];
    }
  }

  // So instanceof works
  _exports.TrackedWeakSet = TrackedWeakSet;
  Object.setPrototypeOf(TrackedWeakSet.prototype, WeakSet.prototype);
});