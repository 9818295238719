define("tracked-built-ins/-private/map", ["exports", "ember-tracked-storage-polyfill"], function (_exports, _emberTrackedStoragePolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TrackedWeakMap = _exports.TrackedMap = void 0;
  let _Symbol$iterator, _Symbol$toStringTag, _Symbol$toStringTag2;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  _Symbol$iterator = Symbol.iterator;
  _Symbol$toStringTag = Symbol.toStringTag;
  class TrackedMap {
    readStorageFor(key) {
      const {
        storages
      } = this;
      let storage = storages.get(key);
      if (storage === undefined) {
        storage = (0, _emberTrackedStoragePolyfill.createStorage)(null, () => false);
        storages.set(key, storage);
      }
      (0, _emberTrackedStoragePolyfill.getValue)(storage);
    }
    dirtyStorageFor(key) {
      const storage = this.storages.get(key);
      if (storage) {
        (0, _emberTrackedStoragePolyfill.setValue)(storage, null);
      }
    }
    constructor(existing) {
      _defineProperty(this, "collection", (0, _emberTrackedStoragePolyfill.createStorage)(null, () => false));
      _defineProperty(this, "storages", new Map());
      _defineProperty(this, "vals", void 0);
      // TypeScript doesn't correctly resolve the overloads for calling the `Map`
      // constructor for the no-value constructor. This resolves that.
      this.vals = existing ? new Map(existing) : new Map();
    }

    // **** KEY GETTERS ****
    get(key) {
      // entangle the storage for the key
      this.readStorageFor(key);
      return this.vals.get(key);
    }
    has(key) {
      this.readStorageFor(key);
      return this.vals.has(key);
    }

    // **** ALL GETTERS ****
    entries() {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      return this.vals.entries();
    }
    keys() {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      return this.vals.keys();
    }
    values() {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      return this.vals.values();
    }
    forEach(fn) {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      this.vals.forEach(fn);
    }
    get size() {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      return this.vals.size;
    }
    [_Symbol$iterator]() {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      return this.vals[Symbol.iterator]();
    }
    get [_Symbol$toStringTag]() {
      return this.vals[Symbol.toStringTag];
    }

    // **** KEY SETTERS ****
    set(key, value) {
      this.dirtyStorageFor(key);
      (0, _emberTrackedStoragePolyfill.setValue)(this.collection, null);
      this.vals.set(key, value);
      return this;
    }
    delete(key) {
      this.dirtyStorageFor(key);
      (0, _emberTrackedStoragePolyfill.setValue)(this.collection, null);
      return this.vals.delete(key);
    }

    // **** ALL SETTERS ****
    clear() {
      this.storages.forEach(s => (0, _emberTrackedStoragePolyfill.setValue)(s, null));
      (0, _emberTrackedStoragePolyfill.setValue)(this.collection, null);
      this.vals.clear();
    }
  }

  // So instanceof works
  _exports.TrackedMap = TrackedMap;
  Object.setPrototypeOf(TrackedMap.prototype, Map.prototype);
  _Symbol$toStringTag2 = Symbol.toStringTag;
  class TrackedWeakMap {
    readStorageFor(key) {
      const {
        storages
      } = this;
      let storage = storages.get(key);
      if (storage === undefined) {
        storage = (0, _emberTrackedStoragePolyfill.createStorage)(null, () => false);
        storages.set(key, storage);
      }
      (0, _emberTrackedStoragePolyfill.getValue)(storage);
    }
    dirtyStorageFor(key) {
      const storage = this.storages.get(key);
      if (storage) {
        (0, _emberTrackedStoragePolyfill.setValue)(storage, null);
      }
    }
    constructor(existing) {
      _defineProperty(this, "storages", new WeakMap());
      _defineProperty(this, "vals", void 0);
      // TypeScript doesn't correctly resolve the overloads for calling the `Map`
      // constructor for the no-value constructor. This resolves that.
      this.vals = existing ? new WeakMap(existing) : new WeakMap();
    }
    get(key) {
      this.readStorageFor(key);
      return this.vals.get(key);
    }
    has(key) {
      this.readStorageFor(key);
      return this.vals.has(key);
    }
    set(key, value) {
      this.dirtyStorageFor(key);
      this.vals.set(key, value);
      return this;
    }
    delete(key) {
      this.dirtyStorageFor(key);
      return this.vals.delete(key);
    }
    get [_Symbol$toStringTag2]() {
      return this.vals[Symbol.toStringTag];
    }
  }

  // So instanceof works
  _exports.TrackedWeakMap = TrackedWeakMap;
  Object.setPrototypeOf(TrackedWeakMap.prototype, WeakMap.prototype);
});